<template>
  <div>
    <a-divider />
    <div class="buding_title">
      <h1 style="font-size: 0.46875rem">数 字 孪 生 智 慧 鱼 塘</h1>
      <a-spin :spinning="spinning" tip="客官请稍等，小的马上就来"></a-spin>
    </div>

    <!--div class="school_contain">
      <div class="buding_title">
        <h1 style="font-size: 0.46875rem">数 字 孪 生 智 慧 鱼 塘</h1>
      </div>
      <img
        width="90%"
        height="600"
        style="display: inline-block"
        src="../../assets/IotPlatfrom/image002.jpg"
        alt=""
        srcset=""
      />
    </div-->
    <a-button @click="() => run()" type="primary">运行</a-button>
    <a-button @click="() => pause()" type="primary">停止</a-button>
    <a-button @click="() => run2()" type="primary">投料</a-button>
    <div id="container"></div>
    <a-layout-footer :style="{ textAlign: 'center' }">
      YCL ©2022 Created by ruan and yong
    </a-layout-footer>
  </div>
</template>
<style scoped>
/* 标题 */
.buding_title {
  display: inline-block;
  position: absolute;
  width: 10.9375rem;
  top: 0.78125rem;
  left: 8.1625rem;
  transform: scale(0.6);
  text-align: center;
  border: 2px solid;
  background-color: rgb(185, 209, 235);
}

/* 背景 */
.schoolroom {
  margin-top: -5%;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

/* 布局大小 */
.school_contain {
  margin: 0 auto;
  position: relative;
  width: 19.2rem;
}
</style>
<script>
//@click="onMouseClick($event)
// import Left from '../components/Buding/Left/Left.vue'
//import Left from '../../components/IotPlatfrom/Buding/Left/Left'
//import Right from '../../components/IotPlatfrom/Buding/Right/Right'
import * as Three from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  name: "Buding",
  props: ['message'],

  data() {
    return {
      camera: null,

      scene: null,

      renderer: null,

      mesh: null,

      controls: null,

      tag: 0,

      fish: null,

      loader: null,
      spinning: null,
      clock: null,
      mixer: null,
      action: null,
      es: null
    };
  },

  computed: {
    store3dscatter() {
      return this.$store.state.scatter3d;　　//需要监听的数据
    }
  },
  watch: {

    store3dscatter(newVal, oldVal) {
      //do something

      console.log(newVal);
      if (newVal.dt == "car_on") this.run();
      else if (newVal.dt == "car_off" || newVal.dt == "feed_off") this.pause();
      else if (newVal.dt == "feed_on") this.run2();
    }

  },

  methods: {
    onMouseClick(e) {
      //X轴,Y轴,Z轴旋转的弧度
      /*if(this.tag == 0){
          this.mesh.scale.set(3,3,3)
          this.tag = 1
        }else if(this.tag == 1){
          this.mesh.scale.set(1,1,1)
          this.tag = 0
        }*/
      //this.mesh.position.x += 4;
      /*let raycaster = new Three.Raycaster();
      let mouse = new Three.Vector2();
      let modelList = [this.mesh];
      mouse.x = (e.clientX / container.clientWidth) * 2 - 1;
      mouse.y = -(e.clientY / container.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, this.camera);
      let intersects = raycaster.intersectObjects(modelList, false); //array
      console.log(intersects);
      if (intersects.length > 0) {
        let selectedObject = intersects[0];
        alert("鱼塘状况： 温度:20摄氏度");
      }*/
      // 获取屏幕坐标
      console.log("点击了")
      let vector = new Three.Vector3(e.clientX / window.innerWidth * 2 - 1, -(e.clientY / window.innerHeight) * 2 + 1, 0.5)
      // 将屏幕坐标转换为three.js场景坐标（鼠标点击位坐标置转三维坐标）
      vector = vector.unproject(this.camera)

      // 摄像机位置向场景发射“光线”
      const raycaster = new Three.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize())
      // 判断指定的对象中哪些被该光线照射到了
      const intersects = raycaster.intersectObjects([this.mesh])

      if (intersects.length > 0) {
        // 旋转网格(mesh)
        console.log(intersects[0])
        alert("鱼塘状况： 温度:20摄氏度");
        /*intersects[0].object.rotation.x += 10 * Math.PI / 180
        intersects[0].object.rotation.y += 10 * Math.PI / 180
        intersects[0].object.rotation.z += 10 * Math.PI / 180*/
      }
    },
    init() {
      let container = document.getElementById("container");

      this.camera = new Three.PerspectiveCamera(
        75,

        container.clientWidth / container.clientHeight,

        0.1,

        1000
      );
      // console.log("执行成功")


      //this.camera.position.z = 15;
      this.camera.position.z = 10;
      this.camera.position.y=1.25;
      //this.camera.position.y = 20;
      //this.camera.position.x = -10;

      this.scene = new Three.Scene();

      this.scene.background = new Three.Color(0xffffff);


      /* let geometry = new Three.BoxGeometry(0.5, 0.5, 0.5);
 
        let material = new Three.MeshBasicMaterial({
             color: 0x00ff00
         });
 
       this.mesh = new Three.Mesh(geometry, material);
 
       this.mesh.position.y += 0.3;
       this.mesh.position.x -= 0.8;
 
       this.scene.add(this.mesh);*/
      this.clock = new Three.Clock();
      // let ambientLight = new Three.AmbientLight(0x191970, 20);
      let ambientLight = new Three.AmbientLight(0xffffff, 1.8);
      this.scene.add(ambientLight);

      this.loader = new GLTFLoader();

      let that = this;

      this.loader.load(
        "/models/good.glb",
        function (gltf) {
          console.log(gltf);
          // gltf.position.set(0,0,0);
          that.scene.add(gltf.scene);
          let root = gltf.scene;
          //root.position.x-=1;
          //root.rotation.x=0.35*Math.PI;
          //that.mesh.rotation.x=0.35*Math.PI;
          that.fish = gltf;
          root.rotation.y = Math.PI;
          root.position.x -= 0.8;
          root.position.z = 1.8;

          that.scene.add(gltf.scene);
          that.spinning = false;
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );
      /* let url = `https://api.rslly.top/sse/connect/dt`;
       this.es = new EventSourcePolyfill(url, {
         headers:{
           Authorization:localStorage.getItem('Authorization')
       },
       heartbeatTimeout:1000*7200
       });
  
       this.es.onopen = function(event) {
         //console.log("eiwuew")
        console.log("连接成功", event);
       };
  
       this.es.onmessage = function(event) {
         // to to something…
         console.log("接收信息", event.data);
         if(event.data=="car_on"){
         that.run();     
         }else if (event.data=="car_off"||event.data=="feed_off"){
           that.pause();
         } else if(event.data=="feed_on"){
           that.run2();
         }
         //that.$message.success("脚本执行成功")
       };
  
       this.es.onerror = function(error) {
         // 监听错误
         console.log("错误", error);
       };*/

      this.renderer = new Three.WebGLRenderer({ antialias: true });

      this.renderer.setSize(container.clientWidth, container.clientHeight);

      //this.renderer.domElement.addEventListener("click", this.onMouseClick, true);

      container.appendChild(this.renderer.domElement);
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    },
    /**
     * 启动特定网格对象的动画。在三维模型的动画数组中按名称查找动画
     * @param skinnedMesh {THREE.SkinnedMesh} 要设置动画的网格
     * @param animations {Array} 数组，包含此模型的所有动画
     * @param animationName {string} 要启动的动画的名称
     * @return {Three.AnimationMixer} 要在渲染循环中使用的混合器
     */
    startAnimation(skinnedMesh, animations, animationName) {
      const m_mixer = new Three.AnimationMixer(skinnedMesh);
      const clip = Three.AnimationClip.findByName(animations, animationName);

      if (clip) {
        this.action = m_mixer.clipAction(clip);

        if (animationName == this.fish.animations[1].name) {
          this.action.timeScale = 0.2;
          this.action.loop = Three.LoopOnce;
        } else {
          this.action.timeScale = 1;
        }
        console.log(this.action.time)
        this.action.clampWhenFinished = true;
        //this.action.play();


      }

      return m_mixer;
    },
    run() {
      let that = this;
      console.log(this.fish)
      // 使用动画混合器及配置
      this.mixer = this.startAnimation(
        that.fish.scene,
        that.fish.animations,
        that.fish.animations[1].name // animationName，这里是"Run"
      );
      this.action.play();
    },

    run2() {
      let that = this;
      console.log(this.fish)
      // 使用动画混合器及配置
      this.mixer = this.startAnimation(
        that.fish.scene,
        that.fish.animations,
        that.fish.animations[0].name // animationName，这里是"Run"
      );
      this.action.play();
    },
    pause() {
      if (this.action) {
        this.action.stop()
      }
    },
    animate() {
      //this.mesh.rotation.x += 0.1;
      //this.fish.rotation.x +=0.1;
      //if(this.fish!=null)this.fish.rotation.y +=Math.PI //以世界坐标y轴旋转180°


      if (this.mixer) {
        this.mixer.update(this.clock.getDelta());
      }
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    },
  },
  created() {
    this.spinning = true;
  },

  mounted() {
    this.init();

    this.animate();
  },
};
</script>

<style scoped>
#container {
  margin-top: -10rpx;
  height: 600px;
}
</style>
